@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  font-family: "Poppins", sans-serif;
}

html {
  overflow-y: scroll;
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  background: #1e1e1e;
  color: white;
}
/*# sourceMappingURL=index.css.map */
.loading-bg {
  display: -ms-grid;
  display: grid;
  place-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: black;
  opacity: 0.5;
}

.form-container {
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.form-container h5 {
  width: 600px;
}

form {
  border-style: solid;
  padding: 50px;
  border-width: 1px;
  border-radius: 10px;
}

form .inputContainer {
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 500px;
}

form .inputTitle {
  margin: 0 1vw 0 0;
  width: 120px;
  text-align: left;
}

form .inputSeparator {
  width: 50px;
  margin: 0 1vw;
}

form input,
form textarea,
form select {
  padding: 15px;
  width: 200px;
  outline-style: none;
  border-style: none;
  border-style: solid;
  border-width: 1px;
  border-color: #acacac;
  border-radius: 5px;
  font-size: 15px;
  -ms-box-sizing: content-box;
  box-sizing: content-box;
}

.table-wrapper {
  overflow-y: scroll;
  flex-grow: 1;
  max-height: 576px;
  width: 800px;
}

/* Works on Firefox */
.table-wrapper {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
.table-wrapper::-webkit-scrollbar {
  width: 6px;
}

.table-wrapper::-webkit-scrollbar-track {
  background: #414141;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background-color: #d32424;
  border-radius: 20px;
}

.pagination ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  list-style-type: none;
}

.pagination ul .active-class {
  color: #d32424;
}

.pagination ul li {
  width: 5vw;
  margin-top: 15px;
  margin-bottom: 15px;
}

.pageviewReport {
  border-spacing: 1;
  border-collapse: collapse;
  background: #414141;
  border-radius: 6px;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.pageviewReport * {
  position: relative;
}

.pageviewReport thead tr {
  height: 60px;
  font-size: 16px;
}

.pageviewReport tbody tr {
  height: 48px;
  border-bottom: 1px solid #383838;
}

.pageviewReport tbody tr:last-child {
  border: 0;
}

.pageviewReport th {
  position: -webkit-sticky;
  position: sticky;
  background: #d32424;
  z-index: 1;
  top: 0;
}

.pageviewReport .views {
  text-align: center;
  padding-right: 20px;
}

.hash-cont {
  display: flex;
  flex-direction: row;
  padding-top: 40px;
}

.hash-cont .hash a {
  background: #d32424;
  color: #e6e6e6;
  text-decoration: none;
  padding: 15px;
  margin: 15px;
  font-weight: bold;
  border-radius: 5px;
}

.hash-cont .hash a:hover {
  color: white;
}
/*# sourceMappingURL=App.css.map */
.dashboard {
     display: grid;
       place-items: center;
       width: 100%;
     }

     .imageUploadContainer{
       background: none;
       color: black;
     }

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}

.editor-class {
  background-color: lightgray;
  padding: 1rem;
}

.toolbar-class {
  border: 1px solid #ccc;
  display: flex;
  color: black;
  flex-direction: row;
  background: white;
}

.DraftEditor-root {
  padding: 0px 50px 0px 50px;
  background: #2d2829;
  color: #ffe36c;
  font-size: 1rem;
}
/*# sourceMappingURL=RichTextAnnounce.css.map */
