.dashboard {
     display: grid;
       place-items: center;
       width: 100%;
     }

     .imageUploadContainer{
       background: none;
       color: black;
     }
